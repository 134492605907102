import React from 'react'
import styled from 'styled-components'

const TabTitle = ({ title, i, tabActive, setTabActive }) => (
  <ListElement className={tabActive === i ? 'active' : ''}>
    <TabTitleButton onClick={() => setTabActive(i)} className={tabActive === i ? 'active' : ''}>
      {title}
    </TabTitleButton>
  </ListElement>
)

export default TabTitle

const TabTitleButton = styled.button`
  position: relative;
  font-size: 2.2rem;
  font-weight: 300;
  -webkit-font-smoothing: auto;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  color: #fff;
  transition: opacity 0.3s ease, color 0.3s ease;
  cursor: pointer;

  @media (max-width: 1260px) {
    font-size: 2rem;
  }

  &:hover {
    opacity: 0.6;
  }

  &:focus {
    outline: 0;
  }

  &.active {
    color: ${props => props.theme.colours.primary};

    &:hover {
      opacity: 1;
    }
  }
`

const ListElement = styled.li`
  margin: 0;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`
