import React, { useState } from 'react'
import { graphql } from 'gatsby'

import VideoPlaceholder from './video-placeholder'
import VideoModal from './video-modal'

export const fragment = graphql`
  fragment ProductVideoFragment on wordpress__PAGEAcf {
    video {
      addVideo
      stillImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      video {
        localFile {
          url
        }
      }
    }
  }
`

const Video = ({ addVideo, stillImage, video }) => {
  const [videoActive, setVideoActive] = useState(false)
  const isVideoActive = !!videoActive

  // Inverts the state
  const handleVideoActive = () => setVideoActive(!videoActive)

  return (
    <>
      {addVideo === 'yes' && (
        <>
          <VideoPlaceholder still={stillImage} onVideoActiveChange={handleVideoActive} />
          <VideoModal videoSrc={video.localFile.url} isVideoActive={isVideoActive} onVideoActiveChange={handleVideoActive} />
        </>
      )}
    </>
  )
}

export default Video
