import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import Title from '../title'
import Button from '../button'
import TabTitle from './tab-title'
import TabContent from './tab-content'
import TabTitleSelect from './tab-titles-select'
import ScreenImage from './screen-image'
import ArrowRightSvg from '../../images/arrow-right.svg'
import ArrowLeftSvg from '../../images/arrow-left.svg'

export const fragment = graphql`
  fragment ProductCoreFeaturesFragment on wordpress__PAGEAcf {
    coreFeatures {
      title
      tabs {
        content
        subtitle
        title
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      macbookMockup {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const CoreFeatures = ({ title, tabs, macbookMockup, macbookGlare }) => {
  const [tabActive, setTabActive] = useState(0)

  const handlePrevArrow = () => {
    if (tabActive - 1 >= 0) {
      setTabActive(tabActive - 1)
    } else {
      setTabActive(tabs.length - 1)
    }
  }
  const handleNextArrow = () => {
    if (tabActive + 1 < tabs.length) {
      setTabActive(tabActive + 1)
    } else {
      setTabActive(0)
    }
  }

  return (
    <Outer>
      <Inner className="container">
        <Title type="h2" text={title} />
        <Laptop>
          <MacbookMask>
            {tabs.map((tab, i) => (
              <ScreenImage image={tab.image} key={i} i={i} tabActive={tabActive} />
            ))}
          </MacbookMask>
          <MacbookRender fluid={macbookMockup.localFile.childImageSharp.fluid} />
        </Laptop>
        <Tabs>
          <TabTitles>
            <Arrows>
              <ArrowLeft onClick={() => handlePrevArrow()} whileHover={{ scale: 1.2 }}>
                <ArrowLeftSvg />
              </ArrowLeft>
              <ArrowRight onClick={() => handleNextArrow()} whileHover={{ scale: 1.2 }}>
                <ArrowRightSvg />
              </ArrowRight>
            </Arrows>
            {tabs.map((tab, i) => (
              <TabTitle
                title={tab.title}
                key={i}
                i={i}
                tabActive={tabActive}
                setTabActive={setTabActive}
              />
            ))}
          </TabTitles>
          <TabTitlesSelect onChange={e => setTabActive(parseInt(e.target.value))}>
            {tabs.map((tab, i) => (
              <TabTitleSelect
                title={tab.title}
                key={i}
                i={i}
                tabActive={tabActive}
                setTabActive={setTabActive}
              />
            ))}
          </TabTitlesSelect>
          <TabContentContainer>
            {tabs.map((tab, i) => (
              <TabContent content={tab} key={i} i={i} tabActive={tabActive} />
            ))}
          </TabContentContainer>
        </Tabs>
        <Gradient />
      </Inner>
    </Outer>
  )
}

const Outer = styled.section`
  position: relative;
  padding: 10rem 0 0;
  overflow: hidden;
  line-height: 1.65;

  @media (max-width: 850px) {
    overflow: visible;
    padding: 5rem 0;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    position: relative;
    font-size: 5.6rem;
    font-weight: 300;
    letter-spacing: -0.1rem;
    margin-bottom: 4rem;
    opacity: 0.8;
    z-index: 5;

    @media (max-width: 650px) {
      font-size: 4rem;
    }
  }
`

const Tabs = styled.section`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 57rem;
  z-index: 5;

  @media (max-width: 1260px) {
    max-width: 47rem;
    padding-right: 3rem;
  }

  @media (max-width: 850px) {
    max-width: 60rem;
    top: 0;
    position: relative;
    right: auto;
    margin: 5rem auto 0;
    padding-right: 0;
  }
`

const TabTitles = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 0 2.5rem;
  margin: 0 0 4rem;
  border-bottom: 1px solid #707070;

  @media (max-width: 850px) {
    display: none;
  }
`

const TabTitlesSelect = styled.select`
  display: none;
  font-size: 2.2rem;
  font-weight: 300;
  -webkit-font-smoothing: auto;
  color: ${props => props.theme.colours.primary};
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 40rem;
  box-sizing: border-box;
  margin: 0 auto;
  border: 1px solid ${props => props.theme.colours.primary};
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0My42MjUiIGhlaWdodD0iMjMuMjI3IiB2aWV3Qm94PSIwIDAgNDMuNjI1IDIzLjIyNyI+CiAgPGcgaWQ9Ikdyb3VwXzE1IiBkYXRhLW5hbWU9Ikdyb3VwIDE1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjMwMi42ODggMzE0LjI0OCkgcm90YXRlKC05MCkiPgogICAgPHBhdGggaWQ9IlVuaW9uXzEiIGRhdGEtbmFtZT0iVW5pb24gMSIgZD0iTS4yNjEsMjEuMTA1LDAsMjEuMzY2bC4yNjEtLjI2MUwwLDIwLjg0NWwuMjYxLjI2MUwyMS4zNjYsMCwuMjYxLDIxLjEwNSwyMS4zNjYsNDIuMjExWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjkyLjE3NSAyMzAzLjM5NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwZmZlMiIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1em auto, 100%;

  @media (max-width: 850px) {
    display: block;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }
`

const TabContentContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: left;

  @media (max-width: 850px) {
    text-align: center;
  }
`

const Arrows = styled.aside`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0.7rem;
  left: 0;
  width: 100%;

  @media (max-width: 850px) {
    display: none;
  }
`

const ArrowLeft = styled(motion.div)`
  position: relative;
  left: -5rem;
  width: 1rem;
  height: 2rem;
  cursor: pointer;
  z-index: 10;

  svg {
    width: 100%;
    height: 100%;
  }
`

const ArrowRight = styled(motion.div)`
  position: relative;
  right: -5rem;
  width: 1rem;
  height: 2rem;
  cursor: pointer;
  margin-left: 2rem;
  z-index: 10;

  svg {
    width: 100%;
    height: 100%;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 15%;
  left: -150%;
  width: 250%;
  height: 55%;
  background: linear-gradient(0deg, #00ffe2 0%, #8b01b4 100%);
  filter: blur(50px);
  opacity: 0.12;
  transform: perspective(744px) rotateY(-13deg) rotateZ(-1deg);
  z-index: 2;
`

const MacbookMask = styled.div`
  position: absolute;
  top: 5.2%;
  left: 11.5%;
  width: 77%;
  height: 82.5%;
  z-index: 5;
`

const Laptop = styled.div`
  position: relative;
  width: 75%;
  margin: 5rem 0;
  z-index: 1;

  @media (max-width: 850px) {
    display: none;
  }
`

const MacbookRender = styled(Img)`
  position: relative;
  width: 100%;
  z-index: 3;
`

export default CoreFeatures
