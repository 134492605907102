import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import { motion } from 'framer-motion'
import { fadeInUp } from '../animation/animations'

import Title from '../title'
import Button from '../button'
import ContactModal from '../contact-modal'

export const fragment = graphql`
  fragment ProductOverviewFragment on wordpress__PAGEAcf {
    overview {
      buttonText
      mainText
      subtitle
      title
    }
  }
`

const Overview = ({ title, subtitle, mainText, buttonText }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const handleContactModalOpen = () => setContactModalOpen(!contactModalOpen)
  const [animation, setAnimation] = useState(false)
  const handleActive = () => setAnimation(true)

  useEffect(() => {
    // Force a resize event to trigger Waypoints that are already visible on page load
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 2000)
  })

  return (
    <>
      <Outer>
        <Inner className="container">
          <Waypoint onEnter={() => handleActive()} scrollableAncestor="window" bottomOffset="10%" />
          <Title text={title} />
          <Subtitle
            initial="hidden"
            animate={animation ? 'visible' : 'hidden'}
            variants={fadeInUp}
            custom="0.5"
          >
            {subtitle}
          </Subtitle>
          <Content
            initial="hidden"
            animate={animation ? 'visible' : 'hidden'}
            variants={fadeInUp}
            custom="0.6"
          >
            {mainText}
          </Content>
          <motion.div
            initial="hidden"
            animate={animation ? 'visible' : 'hidden'}
            variants={fadeInUp}
            custom="0.7"
          >
            <Link to="/engagement#form">
              <Button type="button" click={handleContactModalOpen} text={buttonText} />
            </Link>
          </motion.div>
        </Inner>
      </Outer>
    </>
  )
}

const Outer = styled.section`
  position: relative;
  padding: 10rem 0;
`

const Inner = styled.div`
  max-width: 68rem;
  text-align: center;

  button {
    margin-top: 3rem;
  }

  h2 {
    font-size: 4.6rem;
    font-weight: 700;
    margin-bottom: 4rem;
  }
`

const Subtitle = styled(motion.h3)`
  font-size: 2.8rem;
  font-weight: 100;
  letter-spacing: 0.1rem;
  margin-bottom: 4rem;
`

const Content = styled(motion.p)`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  opacity: 0.8;

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

export default Overview
