export const iconHover = {
  rest: {
    scale: 1
  },
  hover: {
    scale: 1.05,
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 10,
      mass: 1,
      velocity: 0
    }
  }
}

export const gradientAnimation = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      delay: 0.7
    }
  },
  hidden: {
    opacity: 0,
    scale: 0.8
  }
}

export const headerOneAnimation = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5
    }
  },
  hidden: {
    opacity: 0
  }
}

export const headerTwoAnimation = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5
    }
  },
  hidden: {
    opacity: 0
  }
}
