import React, { useState, useEffect, useCallback } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { iconHover, gradientAnimation, headerOneAnimation, headerTwoAnimation } from './animation'

import ContactModal from '../contact-modal'
import IconSvg from '../../images/cube-icon.svg'

export const fragment = graphql`
  fragment ProductHeroFragment on wordpress__PAGEAcf {
    hero {
      productTitle
      background {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      productScreenshot {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      gradient {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const HomeHero = ({ productTitle, background, productScreenshot, gradient }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const handleContactModalOpen = () => setContactModalOpen(!contactModalOpen)
  const gradientControls = useAnimation()
  const headerOneControls = useAnimation()
  const headerTwoControls = useAnimation()

  const sequence = useCallback(async () => {
    await gradientControls.start('visible')
    await headerOneControls.start('visible')
    await headerTwoControls.start('visible')
  }, [gradientControls, headerOneControls, headerTwoControls])

  useEffect(() => {
    sequence()
  }, [sequence])

  return (
    <>
      <Outer>
        <ImageContainer>
          <div>
            <ScreenshotMask>
              <Screenshot
                fluid={productScreenshot.localFile.childImageSharp.fluid}
                loading="eager"
                durationFadeIn={0}
                fadeIn={false}
                style={{ position: 'absolute' }}
              />
            </ScreenshotMask>
            <Gradient>
              <AnimationContainer
                animate={gradientControls}
                variants={gradientAnimation}
                initial="hidden"
              >
                <Img
                  fluid={gradient.localFile.childImageSharp.fluid}
                  loading="eager"
                  durationFadeIn={0}
                  fadeIn={false}
                  style={{ position: 'absolute' }}
                />
              </AnimationContainer>
            </Gradient>
            <Background
              fluid={background.localFile.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={0}
              fadeIn={false}
              style={{ position: 'absolute' }}
            />
          </div>
        </ImageContainer>
        <Inner className="container">
          <motion.div animate={headerOneControls} variants={headerOneAnimation} initial="hidden">
            <SmallTitle>Product</SmallTitle>
          </motion.div>
          <motion.h1 animate={headerTwoControls} variants={headerTwoAnimation} initial="hidden">
            {productTitle}
          </motion.h1>

          <DemoCtaContainer className="container" to="/engagement#form">
            <DemoCta initial="rest" whileHover="hover" animate="rest">
              <p>Request a Demo</p>
              <IconContainer>
                <IconSvg />
                <IconBackground variants={iconHover} />
              </IconContainer>
            </DemoCta>
          </DemoCtaContainer>
        </Inner>
      </Outer>

      <ContactModal
        title="Request a Call Back"
        isOpen={contactModalOpen}
        handleContactModalOpen={handleContactModalOpen}
      />
    </>
  )
}
export default HomeHero

const Outer = styled.section`
  position: relative;

  @media (max-width: 900px) {
    padding-bottom: 5rem;
  }

  @media (max-width: 650px) {
    padding: 7rem 0 0;
    background: linear-gradient(0deg, ${props => props.theme.colours.black} 0%, #212021 100%);
  }
`

const Inner = styled.div`
  position: absolute;
  left: 50%;
  bottom: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  transform: translateX(-50%);
  max-width: 100%;
  z-index: 15;

  @media (max-width: 1200px) {
    bottom: 0;
  }

  @media (max-width: 650px) {
    bottom: 3%;
    padding: 0;
  }

  @media (min-width: 1920px) {
    bottom: 10%;
  }

  h1 {
    font-weight: 700;
    font-size: 6.6em;
    line-height: 1.2;
    margin-bottom: 0;

    @media (max-width: 650px) {
      font-size: 5rem;
      padding: 0;
    }
  }
`

const SmallTitle = styled.p`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1rem;

  @media (max-width: 340px) {
    display: none;
  }
`

const ImageContainer = styled.div`
  ${aspectRatio(1440, 810)}
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  @media (max-width: 1200px) {
    ${aspectRatio(4, 3)}
  }
`

const AnimationContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform, opacity;

  > * {
    width: 100%;
  }
`

const Background = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const ScreenshotMask = styled.figure`
  ${aspectRatio(586, 330)}
  position: absolute;
  top: 15.7%;
  left: 50%;
  width: 47%;
  transform: translateX(-50%);
  overflow: hidden;
  z-index: 10;

  @media (max-width: 1200px) {
    width: 62.5%;
  }
`

const Screenshot = styled(Img)`
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  z-index: 1;
`

const Gradient = styled(motion.figure)`
  ${aspectRatio(4, 0.6)}
  position: absolute;
  bottom: 0;
  left: -20%;
  width: 140%;
  transform: translateY(-5%);
  z-index: 2;

  @media (max-width: 1200px) {
    bottom: 6%;
  }
`

const DemoCtaContainer = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  text-decoration: none;
`

const DemoCta = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  right: 3rem;
  cursor: pointer;
  z-index: 5;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
  }

  p {
    position: relative;
    font-size: 1.3rem;
    font-weight: 300;
    margin: 0;
    margin-right: 2.5rem;
    padding-right: 5.5rem;

    @media (max-width: 1000px) {
      margin: 0;
      padding: 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 4rem;
      height: 0.1rem;
      background: #fff;
      transform: translateY(-50%);

      @media (max-width: 1000px) {
        position: relative;
        top: 50%;
        right: auto;
        transform: translateY(0);
        margin: 1rem auto;
        height: 3rem;
        width: 1px;
      }
    }
  }

  svg {
    position: relative;
    width: 2rem;
    height: 2rem;
    z-index: 5;
  }
`

const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
`

const IconBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colours.primary};
  border-radius: 50%;
  z-index: 1;
`
