import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { fadeIn } from '../animation/animations'

const TabContent = ({ content, i, tabActive }) => {
  const isOpen = i === tabActive

  return (
    <>
      {isOpen && (
        <TabContentItem initial="hidden" animate="visible" variants={fadeIn}>
          <TabTextContainer>
            <Content dangerouslySetInnerHTML={{ __html: content.content }} />
          </TabTextContainer>
        </TabContentItem>
      )}
    </>
  )
}

export default TabContent

const TabContentItem = styled(motion.div)`
  display: flex;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 1260px) {
    width: 100%;
    padding-right: 3rem;
  }

  @media (max-width: 1260px) {
    width: 100%;
    padding-right: 3rem;
  }

  @media (max-width: 850px) {
    padding-right: 0;
  }

  .container {
    padding: 0;
    margin: 0;
    z-index: 5;

    > div {
      margin-bottom: 0;
    }
  }
`

const TabTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 20rem;

  @media (max-width: 850px) {
    margin: 4rem 0 2rem;
  }

  strong {
    color: #353535;
  }

  ul {
    margin-left: 1.8rem;
  }

  li {
    margin: 0;
  }
`

const Title = styled.p`
  font-size: 3.8rem;
  line-height: 1.6;
  font-weight: 700;

  @media (max-width: 850px) {
    display: none;
  }
`

const Subtitle = styled.p`
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: auto;

  @media (max-width: 850px) {
    font-size: 2.2rem;
    line-height: 1.65;
    text-align: center;
  }
`

const Content = styled.p`
  font-size: 1.6rem;
  line-height: 1.65;
  opacity: 0.8;
  text-align: center;
  margin-bottom: 0;

  @media (max-width: 850px) {
    font-size: 2rem;
  }
`
