import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import SEO from '../components/seo'

import HomeHero from '../components/product/hero'
import Overview from '../components/product/overview'
import CoreFeatures from '../components/product/core-features'
import AllFeatures from '../components/product/all-features'
import Video from '../components/product/video'
import CallToAction from '../components/call-to-action'

const Home = ({ data }) => (
  <>
    <SEO {...data.wordpressPage.yoast_meta} title="data.wordpressPage.title" />
    <Helmet
      bodyAttributes={{
        class: 'product'
      }}
    />
    <HomeHero {...data.wordpressPage.acf.hero} />
    <Overview {...data.wordpressPage.acf.overview} />
    <CoreFeatures {...data.wordpressPage.acf.coreFeatures} />
    <AllFeatures {...data.wordpressPage.acf.allFeatures} />
    <Video {...data.wordpressPage.acf.video} />
    <CallToAction {...data.wordpressPage.acf.callToAction.callToAction} />
  </>
)

export default Home

export const query = graphql`
  query($wordpressId: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpressId }) {
      ...SeoFragment
      acf {
        ...ProductHeroFragment
        ...ProductOverviewFragment
        ...ProductCoreFeaturesFragment
        ...ProductAllFeaturesFragment
        ...ProductVideoFragment
        ...callToActionFragment
      }
    }
  }
`
