import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import { motion, AnimatePresence } from 'framer-motion'
import { fadeIn } from '../animation/animations'

const ScreenImage = ({ image, i, tabActive }) => (
  <>
    <AnimatePresence>
      {tabActive === i && (
        <ScreenImageMotion initial="hidden" animate={tabActive === i ? 'visible' : 'hidden'} exit="exit" variants={fadeIn}>
          <MacbookScreen fluid={image.localFile.childImageSharp.fluid} objectPosition="0 0" loading="eager" durationFadeIn={0} fadeIn={false} />
        </ScreenImageMotion>
      )}
    </AnimatePresence>
  </>
)

export default ScreenImage

const ScreenImageMotion = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`

const MacbookScreen = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0;
`
