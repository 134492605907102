import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import Swiper from 'react-id-swiper'
import { motion } from 'framer-motion'
import { Waypoint } from 'react-waypoint'
import { staggeredFadeInUp } from '../animation/animations'

import Title from '../title'
import ArrowRightSvg from '../../images/arrow-right.svg'
import ArrowLeftSvg from '../../images/arrow-left.svg'

import 'react-id-swiper/lib/styles/css/swiper.css'

export const fragment = graphql`
  fragment ProductAllFeaturesFragment on wordpress__PAGEAcf {
    allFeatures {
      title
      features {
        title
        content
      }
      backgroundImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const AllFeatures = ({ title, features, backgroundImage }) => {
  const [mainSwiper, getMainSwiper] = useState(null)
  const [bulletSwiper, getBulletSwiper] = useState(null)

  const goNext = () => {
    if (mainSwiper !== null) {
      mainSwiper.slideNext()
    }
  }

  const goPrev = () => {
    if (mainSwiper !== null) {
      mainSwiper.slidePrev()
    }
  }

  const mainSwiperParams = {
    getSwiper: getMainSwiper,
    slidesPerGroup: 3,
    slidesPerView: 3,
    slidesPerColumn: 2,
    pagination: {
      el: '.swiper-pagination',
      type: 'custom',
      renderCustom(swiper, current, total) {
        const slidesPerPage = swiper.params.slidesPerView < 2 ? swiper.params.slidesPerView : swiper.params.slidesPerView * 2
        const currentPage = current * slidesPerPage < features.length ? current * slidesPerPage : features.length
        return `Showing ${currentPage} of ${features.length}`
      }
    },
    spaceBetween: 30,
    breakpoints: {
      1000: {
        slidesPerView: 2,
        slidesPerColumn: 2,
        slidesPerGroup: 2
      },
      650: {
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesPerGroup: 1
      }
    }
  }

  const bulletSwiperParams = {
    getSwiper: getBulletSwiper,
    slidesPerGroup: 3,
    slidesPerView: 6,
    slidesPerColumn: 2,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: 'true'
    },
    spaceBetween: 30,
    breakpoints: {
      1000: {
        slidesPerGroup: 2,
        slidesPerView: 2,
        slidesPerColumn: 2,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: 'true'
        }
      },
      650: {
        slidesPerGroup: 1,
        slidesPerView: 1,
        slidesPerColumn: 1,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: 'true',
          dynamicBullets: true
        }
      }
    }
  }

  useEffect(() => {
    if (mainSwiper !== null && mainSwiper.controller && bulletSwiper !== null && bulletSwiper.controller) {
      mainSwiper.controller.control = bulletSwiper
      bulletSwiper.controller.control = mainSwiper
    }
  }, [mainSwiper, bulletSwiper])

  useEffect(() => {
    // A debounce function limit number of side effects on resize
    const debounce = (f, n) => {
      let allowRun = true
      return function(...args) {
        if (allowRun) {
          allowRun = false
          setTimeout(() => (allowRun = true), n)
          return f.apply(f, args)
        }
      }
    }

    const updateSwiperClasses = debounce(() => {
      const mainSwiperEl = document.querySelector('.main-swiper .swiper-container')
      const paginationSwiperEl = document.querySelector('.bullet-swiper .swiper-pagination')

      if (window.innerWidth <= 650) {
        mainSwiperEl.classList.remove('swiper-container-multirow')
        paginationSwiperEl.classList.add('swiper-pagination-bullets-dynamic')
      } else {
        mainSwiperEl.classList.add('swiper-container-multirow')
        paginationSwiperEl.classList.remove('swiper-pagination-bullets-dynamic')
      }
    }, 100)

    // Swiper doesn't take care of updating classes on resize
    // so we need to do this ourselves for certain options
    window.addEventListener('resize', updateSwiperClasses)

    return () => window.removeEventListener('resize', updateSwiperClasses)
  }, [])

  const [animation, setAnimation] = useState(undefined)

  const handleAnimation = () => {
    setAnimation(true)
  }

  return (
    <Outer id="all-features">
      <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="10%" />
      <Inner className="container" animate={animation ? 'visible' : 'hidden'} variants={staggeredFadeInUp.parent}>
        <Title text={title} type="h2" />
        <MainSwiper className="main-swiper">
          <ArrowLeft onClick={() => goPrev()} whileHover={{ scale: 1.2 }} variants={staggeredFadeInUp.child}>
            <ArrowLeftSvg />
          </ArrowLeft>
          <ArrowRight onClick={() => goNext()} whileHover={{ scale: 1.2 }} variants={staggeredFadeInUp.child}>
            <ArrowRightSvg />
          </ArrowRight>
          <Swiper {...mainSwiperParams}>
            {features.map((feature, i) => (
              <motion.div key={i}>
                <SlideText variants={staggeredFadeInUp.child}>
                  <SlideTitle>{feature.title}</SlideTitle>
                  <SlideContent dangerouslySetInnerHTML={{ __html: feature.content }} />
                </SlideText>
              </motion.div>
            ))}
          </Swiper>
        </MainSwiper>
        <BulletSwiper className="bullet-swiper">
          <Swiper {...bulletSwiperParams}>
            {features.map((feature, i) => (
              <div key={i}></div>
            ))}
          </Swiper>
        </BulletSwiper>
      </Inner>
      <Background fluid={backgroundImage.localFile.childImageSharp.fluid} style={{ position: 'absolute' }} />
    </Outer>
  )
}

const Outer = styled.section`
  position: relative;
  padding: 15rem 0 0;
`

const Background = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const Inner = styled(motion.div)`
  width: 100%;

  h2 {
    position: relative;
    font-size: 4.6rem;
    line-height: 1.2;
    font-weight: 700;
    text-align: center;
    margin-bottom: 8rem;
    z-index: 5;
  }
`

const SlideText = styled(motion.div)`
  opacity: 0;

  @media (max-width: 650px) {
    max-width: 50rem;
    margin: 0 auto;
  }
`

const SlideTitle = styled.p`
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.2;

  @media (max-width: 650px) {
    font-size: 3rem;
    text-align: center;
  }
`

const SlideContent = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.6;
  opacity: 0.8;

  @media (max-width: 1000px) {
    font-size: 1.8rem;
  }

  @media (max-width: 650px) {
    font-size: 2rem;
    text-align: center;
  }
`

const MainSwiper = styled.div`
  width: calc(100% - 10rem);
  margin: 0 auto;

  .swiper-wrapper {
    margin-bottom: 10rem;

    @media (max-width: 650px) {
      flex-wrap: nowrap !important;
    }
  }

  .swiper-pagination {
    font-size: 2.2rem;
    font-weight: 700;
    color: #fff;
  }
`

const BulletSwiper = styled.div`
  .swiper-wrapper {
    @media (max-width: 650px) {
      height: 45px !important;
    }
  }

  .swiper-pagination-bullet {
    background: #dbdbdb;
    margin: 0 0.6rem !important;
    opacity: 1;
    transition: transform 0.3s ease;
  }

  .swiper-pagination {
    left: 0;
    transform: translateX(0);
    white-space: auto;

    @media (min-width: 650px) {
      width: 100% !important;
    }

    @media (max-width: 650px) {
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }

  .swiper-pagination-bullet-active {
    background: ${props => props.theme.colours.primary};
    transform: scale(1.5);
  }

  .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    padding: 10px 0;
  }
`

const ArrowLeft = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: -13rem;
  width: 2.1rem;
  height: 4.2rem;
  cursor: pointer;
  z-index: 10;
  left: 0;
  opacity: 0;

  @media (max-width: 1250px) {
    left: 3rem;
  }

  @media (max-width: 650px) {
    top: 45%;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const ArrowRight = styled(motion.div)`
  position: absolute;
  right: -13rem;
  top: 50%;
  width: 2.1rem;
  height: 4.2rem;
  cursor: pointer;
  z-index: 10;
  right: 0;
  opacity: 0;

  @media (max-width: 1250px) {
    right: 3rem;
  }

  @media (max-width: 650px) {
    top: 45%;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export default AllFeatures
