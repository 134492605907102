import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import { motion } from 'framer-motion'
import { fadeInUp } from './animation/animations'

import Button from './button'

import ContactModal from './contact-modal'

export const fragment = graphql`
  fragment callToActionFragment on wordpress__PAGEAcf {
    callToAction {
      callToAction {
        type
        title
        buttonText
        buttonLink {
          path
        }
      }
    }
  }
`

const CallToAction = ({ type, title, buttonText, buttonLink }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const handleContactModalOpen = () => setContactModalOpen(!contactModalOpen)
  const [animation, setAnimation] = useState(false)
  const handleActive = () => setAnimation(true)

  switch (type) {
    case 'link':
      return (
        <CtaOuter>
          <Inner className="container">
            <Waypoint
              onEnter={() => handleActive()}
              scrollableAncestor="window"
              bottomOffset="10%"
            />
            <Title
              initial="hidden"
              animate={animation ? 'visible' : 'hidden'}
              variants={fadeInUp}
              custom="0"
            >
              {title}
            </Title>
            <motion.div
              initial="hidden"
              animate={animation ? 'visible' : 'hidden'}
              variants={fadeInUp}
              custom="0.5"
            >
              <Button type="link" to={buttonLink.path} text={buttonText} />
            </motion.div>
          </Inner>
        </CtaOuter>
      )
    case 'demo':
      return (
        <>
          <CtaOuter>
            <Inner className="container">
              <Waypoint
                onEnter={() => handleActive()}
                scrollableAncestor="window"
                bottomOffset="10%"
              />
              <Title
                initial="hidden"
                animate={animation ? 'visible' : 'hidden'}
                variants={fadeInUp}
                custom="0"
              >
                {title}
              </Title>
              <motion.div
                initial="hidden"
                animate={animation ? 'visible' : 'hidden'}
                variants={fadeInUp}
                custom="0.2"
              >
                <Link to="/engagement#form">
                  <Button type="button" text={buttonText} />
                </Link>
              </motion.div>
            </Inner>
          </CtaOuter>
        </>
      )
    default:
      return null
  }
}

const CtaOuter = styled.section`
  position: relative;
  padding: 14rem 0 16rem;
`

const Inner = styled.div`
  position: relative;
  text-align: center;
`

const Title = styled(motion.p)`
  font-size: 3.4rem;
`

Button.defaultProps = {
  type: 'link',
  to: '/',
  text: 'find out more',
  click: null
}

Button.defaultProps = {
  type: 'demo',
  to: '/',
  text: 'find out more',
  click: null
}

export default CallToAction
